import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/ServicesPage.css';

const services = [
    {
      title: "Consultoría en Corrosión y Protección",
      description:
        "Asesorías en especificaciones, desarrollo e inspección de sistemas de protección contra corrosión.",
      tooltip: "Servicios de consultoría en corrosión para diversas industrias.",
      image: "corrosion.png",
    },
    {
      title: "Especificación y Desarrollo de Pinturas Industriales",
      description:
        "Desarrollo y especificación de pinturas y recubrimientos industriales para diferentes aplicaciones.",
      tooltip: "Experiencia en pinturas industriales y recubrimientos.",
      image: "paint.png",
    },
    {
      title: "Capacitación en Técnicas de Control de Calidad",
      description:
        "Capacitación en técnicas avanzadas de control de calidad en pinturas y revestimientos.",
      tooltip: "Formación especializada en control de calidad.",
      image: "control.png",
    },
    {
      title: "Inspección y Auditoría Técnica",
      description:
        "Inspección de proyectos de pintura y auditorías técnicas para asegurar el cumplimiento de estándares.",
      tooltip: "Servicios de inspección técnica y auditoría.",
      image: "inspection.png",
    },
    {
      title: "Desarrollo de Proyectos a Medida",
      description:
        "Diseño y desarrollo de proyectos a medida según las necesidades del cliente.",
      tooltip: "Soluciones personalizadas para proyectos específicos.",
      image: "project.png",
    },
  ];


  const ServicesPage = () => {
    useEffect(() => {
      AOS.init({ duration: 2000 });
    }, []);

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        {props.text}
      </Tooltip>
    );

    return (
      <Container className="services-page mt-4">
        <Row className="justify-content-center">
          {services.map((service, index) => (
            <Col md={6} lg={4} className="mb-4" key={index}>
              <OverlayTrigger placement="top" overlay={renderTooltip({ text: service.tooltip })}>
                <Card className="service-card" data-aos="fade-up">
                  <Card.Body>
                    <Card.Title className="service-card-title">{service.title}</Card.Title>
                    <Card.Text className="service-card-text">{service.description}</Card.Text>
                  </Card.Body>
                  <Card.Img className="service-card-img" variant="bottom" src={service.image} alt={service.title} />
                </Card>
              </OverlayTrigger>
            </Col>
          ))}
        </Row>
      </Container>
    );
  };

  export default ServicesPage;