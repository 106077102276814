import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../styles/Statistics.css"

const Statistics = () => (
  <Container>
    <Row className="statistics-container">
      <Col className="single-statistic-container">
        <h3 className="statistics-title">50+</h3>
        <p className="statistics-subtitle">Años de Experiencia</p>
      </Col>
      <Col className="single-statistic-container">
        <h3 className="statistics-title">60+</h3>
        <p className="statistics-subtitle">Proyectos</p>
      </Col>
      <Col className="single-statistic-container">
        <h3 className="statistics-title">20+</h3>
        <p className="statistics-subtitle">Proyectos Activos</p>
      </Col>
      <Col className="single-statistic-container">
        <h3 className="statistics-title">100+</h3>
        <p className="statistics-subtitle">Clientes Felices</p>
      </Col>
    </Row>
  </Container>
);

export default Statistics;
