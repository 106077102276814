import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../styles/Brands.css";

const brands = [
  "/codelco_logo.png",
  "/amsa_logo.png",
  "/sherwin_williams_logo.png",
  "/bhp_logo.png",
  "/akzonobel_logo.png",
  "/asmar_logo.png",
  "/cesmec_logo.png",
  "/chilcorrofin_logo.png",
  "/edyce_logo.png",
  "/endesa_logo.png",
  "/anglo_logo.png",
];

const Brands = () => (
  <Container fluid>
    <div className="brands-carousel">
      <Row className="brands-container">
        {brands.concat(brands).map((brand, index) => (
          <Col key={index} className="brand-col">
            <img src={brand} alt={`Brand ${index + 1}`} className="img-fluid brand-icon" />
          </Col>
        ))}
        {brands.concat(brands).map((brand, index) => (
          <Col key={brands.length + index} className="brand-col">
            <img src={brand} alt={`Brand ${brands.length + index + 1}`} className="img-fluid brand-icon" />
          </Col>
        ))}
      </Row>
    </div>
  </Container>
);

export default Brands;
