import React, { useEffect } from 'react';
import { Container, Row, Col, Image, Card, Tooltip, OverlayTrigger } from 'react-bootstrap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../styles/CEOPage.css';

const CEOPage = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    const image = document.querySelector('.ceo-image');
    const scrollPosition = window.pageYOffset;
    image.style.transform = `translateY(${scrollPosition * 0.2}px)`;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.text}
    </Tooltip>
  );

  return (
    <Container className="ceo-page mt-4">
      <Row>
        <Col md={4} className="sticky-image-col">
          <Image src="ceo.jpg" className="ceo-image sticky-top" />
        </Col>
        <Col md={8}>
          <h2 className="p-4">Educación</h2>
          <div className="timeline">
            <div className="timeline-item" data-aos="fade-right">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({ text: 'Educación Básica en Kent School y Saint Gaspar College' })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Title>Educación Básica</Card.Title>
                    <Card.Text>Kent School / Saint Gaspar College</Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
            <div className="timeline-item" data-aos="fade-left">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({
                  text: 'Universitaria en Universidad Técnica del Estado y Universidad de Santiago, Ingeniería de Ejecución',
                })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Title>Universitaria</Card.Title>
                    <Card.Text>
                      (1970-1974) Universidad Técnica del Estado (Técnico Universitario Control de Calidad)
                      <br />
                      Prosecución de Estudios a nivel de Ingeniería de Ejecución Universidad de Santiago. Título Administrador Industrial (Ingeniería de Ejecución)
                      <br />
                      Idiomas: Español e inglés hablado y escrito
                    </Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
            <h2 className="p-4">Habilidades técnicas</h2>
            <div className="timeline-item" data-aos="fade-right">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({ text: 'Conocimientos de usuario en Word, Excel, PowerPoint, Microsoft Project' })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Text>Conocimientos de usuario: Word, Excel, PowerPoint, Microsoft Project</Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
            <h2 className="p-4">Estudios y Seminarios</h2>
            <div className="timeline-item" data-aos="fade-left">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({ text: 'Estudios y seminarios diversos en corrosión, pinturas, técnicas de calidad, etc.' })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Text>
                      <ul className="no-dots">
                        <li>Corrosión, programa PNUD para el Desarrollo (6 meses con experto austriaco Sr. Herbert Hilbert, Cesmec)</li>
                        <li>Pinturas y Recubrimientos Industriales</li>
                        <li>Técnicas de Control de Calidad en Pinturas</li>
                        <li>Metrología de Masas</li>
                        <li>Ensayos no destructivos</li>
                        <li>Electroquímica</li>
                        <li>PAINT RESEARCH INSTITUTE TEDDINGTON INGLATERRA BECA PNUD: Capacitación en pinturas industriales durante seis meses (1980)</li>
                        <li>International Paint (Inglaterra y EE.UU.)</li>
                        <li>Pinturas Hempel Dinamarca, Copenhague</li>
                        <li>Varios cursos relativos a Administración (Universidad Católica), Calidad Total</li>
                        <li>Windows, Office Escuela de Comercio de Santiago</li>
                        <li>Evaluación de Proyectos "Seminarium"</li>
                        <li>Fortalecimiento del Trabajo en Equipo y de Liderazgo Seminario Target (Julio 2001)</li>
                        <li>Técnicas de Revestimiento de Pisos Seminario en "General Polymers Cincinnati, Ohio" (2002)</li>
                        <li>Dos Cursos Técnicos en Cleveland Ohio, "Devoe Coating Seminar" (2004 y 2006)</li>
                        <li>Varios Seminarios en New Castle relacionadas a nuevas Tecnologías de Pinturas y Revestimientos en el Reino Unido (2013-2017)</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
            <h2 className="p-4">Antecedentes y Logros Laborales</h2>
            <div className="timeline-item" data-aos="fade-right">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({ text: 'Diversos logros laborales y posiciones en CESMEC, Sherwin Williams, etc.' })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Text>
                      <ul className="no-dots">
                        <li><strong>1974:</strong> Laboratorio de Calor y Fluidos, laboratorista, (CESMEC)</li>
                        <li><strong>1975-1977:</strong> Inspector Depto. Corrosión y Protección CESMEC LTDA</li>
                        <li><strong>1977-1981:</strong> Trabajos realizados entre ellos: Laboratorista en el Laboratorio De Corrosión y Protección de Cesmec Limitada, Estudios de Corrosión red de agua potable en Santiago</li>
                        <li><strong>1982-1988:</strong> Jefe del Depto. Corrosión y Protección y Polímeros CESMEC</li>
                        <li><strong>1988-1989:</strong> Gerente Técnico de Pinturas Hempel Chile</li>
                        <li><strong>1989-1990:</strong> Jefe del Depto. de Estudios CESMEC</li>
                        <li><strong>1991-1999:</strong> Sub Gerente de Ingeniería y Zonas Sociedad Química Chilcorrofin</li>
                        <li><strong>2000-2002:</strong> Sub Gerente Industrial: Sherwin Williams</li>
                        <li><strong>2003-2008:</strong> Sub Gerente Ingeniería y Ventas Pinturas Industriales, Tricolor Industrial y Devoe Coatings</li>
                        <li><strong>2009-2012:</strong> Asesorías de Inspección en INDUCORN, Especificación Pinturas para ENDESA Generación de Plantas de Termoeléctricas, etc.</li>
                        <li><strong>2013-2018:</strong> Gerente del área de Minería en Chile Para International Paint (Akzonobel)</li>
                        <li><strong>2019:</strong> Trabajos de consultoría e inspección</li>
                        <li><strong>2020:</strong> Consultor Pinturas Renner</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
            <h2 className="p-4">Otros</h2>
            <div className="timeline-item" data-aos="fade-left">
              <OverlayTrigger
                placement="top"
                overlay={renderTooltip({ text: 'Asesor y consultor para diversas empresas y charlas dictadas' })}
              >
                <Card className="timeline-content">
                  <Card.Body>
                    <Card.Text>
                      <ul className="no-dots">
                        <li>Asesor para diversas empresas en especificaciones, desarrollo e inspección</li>
                        <li>Charlas dictadas en varias compañías importantes durante los últimos 5 años</li>
                      </ul>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </OverlayTrigger>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default CEOPage;
