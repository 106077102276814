import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../styles/GoalBanner.css"

const GoalBanner = () => (
  <Container>
    <Row>
      <Col className="pic-container" md={6}>
        <img src="industry.png" alt="Description" className="img-fluid goal-pic" />
      </Col>
      <Col md={6} className="goal-container">
        <h3 className="goal-title">Nuestra Meta</h3>
        <p className="goal-subtitle">Entregar la Ingeniería relativa al mejor uso de los materiales resistentes tanto al desgaste
        como corrosión. Al mismo tiempo, nuestra empresa se enfoca en dar soluciones que se ajusten a los requerimientos del cliente, tanto de usabilidad como financieros.</p>
      </Col>
    </Row>
  </Container>
);

export default GoalBanner;
