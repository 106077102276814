import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => (
  <footer className="bg-dark text-white text-center py-4">
    <Container>
      <Row>
        <Col>&copy; 2024 Burnett technologies. All rights reserved.</Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
