import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MyNavbar from './components/Navbar';
import Banner from './components/Banner';
import ProjectProcess from './components/ProjectProcess';
import GoalBanner from './components/GoalBanner';
import Brands from './components/Brands';
import Statistics from './components/Statistics';
import ContactBanner from './components/ContactBanner';
import Footer from './components/Footer';
import CEOPage from './components/CEOPage';
import ServicesPage from './components/ServicesPage';

const Home = () => {
  return (
    <div>
      <Banner />
      <ProjectProcess />
      <GoalBanner />
      <Brands />
      <Statistics />
      <ContactBanner />
    </div>
  );
};

const App = () => {
  return (
    <div>
      <MyNavbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ceo" element={<CEOPage />} />
        <Route path="/services" element={<ServicesPage />} />
      </Routes>
      <Footer />
    </div>
  );
};

export default App;
