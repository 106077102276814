import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../styles/Banner.css';
import '../styles/Button.css';

const Banner = () => {
  const companyEmail = 'roy@burnett.cl';

  return (
    <div className="banner">
      <div className="banner-content">
        <h1 className="banner-title">Burnett Technologies</h1>
        <p className="banner-subtitle">Empresa de Tecnología y Aplicabilidad de Materiales</p>
        <Button className="roy-dark-button" href={`mailto:${companyEmail}`} target="_blank">
          Empezar proyecto <b className="arrow-button"><FontAwesomeIcon icon={faArrowRight} /></b>
        </Button>
      </div>
    </div>
  );
};

export default Banner;
