import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles/ProjectProcess.css'

const ProjectProcess = () => (
  <Container>
    <Row className="process-container">
      <Col className="process">
        <h3 className="process-title">1. Consultoría de Proyectos</h3>
        <p className="process-subtitle">Entendimiento detallado del problema en base a las necesidades del cliente al ver las falencias de los materiales y tecnologías utilizadas en relación a la corrosión, mantención, y desgaste de aquellos.</p>
      </Col>
      <Col className="process">
        <h3 className="process-title">2. Definición de Tecnologías</h3>
        <p className="process-subtitle">Búsqueda de mejoras en las tecnologías requeridas para la prolongación de la vida útil de la infraestructura y equipos, por el uso y los efectos de la corrosión.</p>
      </Col>
      <Col className="process">
        <h3 className="process-title">3. Tu proyecto mejorado</h3>
        <p className="process-subtitle">Aplicación de las soluciones y mejoras de forma eficaz, oportuna, y sostenible, considerando las necesidades del planeta, los riesgos actuales medioambientales, y las normativas existentes.</p>
      </Col>
    </Row>
  </Container>
);

export default ProjectProcess;
