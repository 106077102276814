import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import '../styles/Navbar.css';

const MyNavbar = () => {
  const companyEmail = 'roy@burnett.cl';

  return( 
    <Navbar bg="white" variant="light" expand="lg" className="roy-navbar">
      <div className="navbar-header">
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Brand href="/">Burnett Technologies</Navbar.Brand>
      </div>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <LinkContainer to="/">
            <Nav.Link className="nav-link">Home</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/services">
            <Nav.Link className="nav-link">Servicios</Nav.Link>
          </LinkContainer>
          <LinkContainer to="/ceo">
            <Nav.Link className="nav-link">CEO</Nav.Link>
          </LinkContainer>
          <Nav.Link className="nav-link" href={`mailto:${companyEmail}`} target="_blank">Contacto</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default MyNavbar;
