import React from 'react';
import { Button } from 'react-bootstrap';
import '../styles/ContactBanner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Container } from 'react-bootstrap';

const ContactBanner = () => {
  const companyEmail = 'roy@burnett.cl';

  return(
    <div className="contact-banner">
      <Container className="contact-container">
      <h2>Tienes un proyecto en mente?</h2>
      <p>Concretemos tu proyecto con nuestro equipo de profesionales</p>
      <Button className="roy-dark-button" href={`mailto:${companyEmail}`} target="_blank">
          Contáctanos <b className="arrow-button"><FontAwesomeIcon icon={faArrowRight} /></b>
        </Button>
      </Container>
    </div>
  )
};

export default ContactBanner;
